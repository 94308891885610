import { host } from '../../../config';
export const emailValidator = async (value) => {
  try {
    const response = await fetch(
      `${host}/api/v1/users/emailcheck?${new URLSearchParams({
        email: value.toLowerCase(),
      })}`,
      {
        method: 'GET',
        cache: 'default',
      },
    );

    const json = await response.json();
    return !json.exists;
  } catch (e) {
    console.log(e);
    return true;
  }
};

export const BEVATCheckDigit = (input) => {
  const r = input.replace(/\+|[^\d]/g, '');
  return (
    9 == r.length && (r = '0' + r),
    0 != r.slice(1, 2) && 97 - (r.slice(0, 8) % 97) == r.slice(8, 10)
  );
};
