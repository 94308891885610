import { setIntercomSettings } from '../../../res/intercom';
import {
  sendAmplitudeData,
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from '../../../res/amplitude';
import { FAlogEvent } from '../../../res/fpc';
import { logEvent } from '../../../res/pixel';
import { country, host } from '../../../config';
import { getUtms } from '../../../res/utms';
import { tap } from '../../../res/tapfiliate';
import { GTMsendEvent } from '../../../res/gtag';

const languageMap = {
  en: 0,
  fr: 1,
  nl: 2,
  de: 3,
};
const countryToString = {
  be: {
    0: 'Belgium',
    1: 'Belgique',
    2: 'Belgie',
  },
  de: {
    0: 'Germany',
    3: 'Deutschland',
  },
};

export const signup = async (data) => {
  try {
    const commonDataFormat = ({ VATType, ...data }) => {
      return {
        authContinuation: true,
        country: data.country ?? country,
        email: data.email,
        firstName: data.firstname,
        lastName: data.lastname,
        password: data.password,
        accountType: `${data.accountType}${data.occupation ? data.occupation : ''
          }`,
        phoneNumber: data.phoneNumber,
        language: languageMap[data.language],
        VATType: VATType
          ? VATType === 'subjectToVAT'
            ? VATType
            : data.noVATStatus
              ? data.noVATStatus
              : VATType
          : 'subjectToVAT',
      };
    };
    const countriesDataFormat = {
      de: ({ VATReturnFrequency, promocode }) => {
        return {
          VATReturnFrequency: VATReturnFrequency,
          ...(promocode && { promocode: promocode }),
          address: {
            country:
              countryToString[data.country ?? country][
              languageMap[data.language]
              ],
          },
        };
      },
      be: ({
        VATNumber,
        promocode,
        complementaryDays,
        NACECodes,
        city,
        zipcode,
        street,
        startDate,
        companyName,
      }) => {
        return {
          ...(VATNumber && { VATNumber: VATNumber.replace(/\.| /g, '') }),
          ...(promocode && { promocode: promocode }),
          ...(companyName && { companyName }),
          ...(complementaryDays && { complementaryDays }),
          ...(complementaryDays && { complementaryDays }),
          ...(NACECodes && { NACECodes }),
          address: {
            ...(city && /^[0-9]{4}$/.test(zipcode) && { city }),
            ...(zipcode && /^[0-9]{4}$/.test(zipcode) && { zip: zipcode }),
            ...(street && /^[0-9]{4}$/.test(zipcode) && { street }),
            country:
              countryToString[data.country ?? country][
              languageMap[data.language]
              ],
          },
          ...(startDate && { startDate }),
        };
      },
    };
    const user = {
      ...commonDataFormat(data),
      ...countriesDataFormat[data.country ?? country](data),
    };
    sendAmplitudeData('WEB_SIGNUP_TRY_SIGNUP');
    const response = await fetch(`${host}/api/v2/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });

    const res = await response.json();
    const utms = getUtms();
    const json = res.data;
    if (res.data && json._id) {
      setIntercomSettings(json, data.language);
      setAmplitudeUserProperties({
        user_country: json.country,
        VAT_type: json.VATType,
        account_type: `${data.accountType}${data.occupation ? data.occupation : ''
          }`,
        account_type: json.account_type,
        phoneNumber: json.phoneNumber,
        email: json.email,
        firstName: json.firstName,
        lastName: json.lastName,
        language: json.language,
        ...(json.VATNumber && { VATNumber: json.VATNumber }),
        ...(json.companyName && { companyName: json.companyName }),
        ...(json.NACECodes && {
          NACE_main: JSON.stringify(
            Object.values(json.NACECodes).map((nc) => nc[0])
          ).slice(1, -1),
        }),
        ...(data.regstration_date && { regstration_date: data.regstration_date }),
        ...(json.startDate && {
          KBO_start_date: new Date(json.startDate).getTime() / 1000,
        }),
        ...(json.address && json.address),
        utms,
        ...utms,
      });
      if (user.promocode) {
        sendAmplitudeData('WEB_SIGNUP_SIGNEDUPWITHPROMOCODE', {
          promocode: user.promocode,
        }, 200, true);
        if (user.promocode.toUpperCase() === 'TAXLEU')
          fetch(
            `https://accountablemuj3pl9f-promocodetogsheet.functions.fnc.fr-par.scw.cloud`,
            {
              mode: 'no-cors',
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                message: user.promocode.toUpperCase(),
                email: json.email,
                aid: json._id,
              }),
            }
          );
      }
      logEvent('CreatedAccount');
      GTMsendEvent('createdAccount', json._id);
      tap('customer', json._id);
      tap('conversion', json._id);
      if (country === 'de') FAlogEvent('sale', json._id);
      await Promise.all([
        setAmplitudeUserId(data.email),
        sendAmplitudeData('WEB_SIGNUP_CREATEDACCOUNT', null, 200, true)
      ]);
      return res.auth_continuation_token;
    } else {
      fetch(
        `https://hooks.slack.com/services/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`,
        {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: data.firstname + " just encountered a bug on the Invoicing WS/RASO (attempt " + data.attempts + ") 😢 \n *Payload:* ```" + JSON.stringify({ ...user, password: '' }) + "``` \n *Response:* ```" + JSON.stringify(res) + "```",
          }),
        }
      );
      return false;
    };
  } catch (e) {
    console.log(e);
    fetch(
      `https://hooks.slack.com/services/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`,
      {
        mode: 'no-cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: data.firstname + " just encountered a bug on the Invoicing WS/RASO (attempt " + data.attempts + ") 😢 \n *Data:* ```" + JSON.stringify({ ...data, password: '' }) + "``` \n *Error:* ```" + JSON.stringify(e) + "```",
        }),
      }
    );
    return false;
  }
};
