import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Common/Button';
import { useUserData } from '../../userData';
import Form from '../Raso/Form';
import { emailValidator } from '../Signup/Form/validators';
import Checkmark from './checkmark.svg';
import Close from './close.svg';
import InvoiceIllustration from './invoice-illustration.png';
import './invoicing.css';
import Logo from './logo.svg';
import { country } from '../../config';

const Invoicing = () => {
  const { t } = useTranslation();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(true);
  const { userData } = useUserData();

  useEffect(() => {
    const checkEmail = async () => {
      if (!userData || !userData.email) {
        setIsEmailExists(false);
        return;
      }

      const emailExists = await emailValidator(userData.email);

      if (!emailExists) {
        setIsEmailExists(!emailExists);
      } else {
        setIsEmailExists(false);
      }
    };
    checkEmail();
  }, [userData]);

  const redirectUser = () => {
    const redirectUrl = process.env.REACT_APP_WEBAPP || '/';
    window.location.href = redirectUrl;
  };

  const contentLists = [
    {
      title: 'invoicing_content_list_head_1',
      desc: 'invoicing_content_list_desc_1',
    },
    {
      title: 'invoicing_content_list_head_2',
      desc: 'invoicing_content_list_desc_2',
    },
    {
      title: 'invoicing_content_list_head_3',
      desc: 'invoicing_content_list_desc_3',
    },
  ];

  return (
    <div className="invoicing">
      {showPasswordForm && (
        <>
          <div className="invocing-modal-wrapper"></div>
          <div className="invoicing-modal">
            <div className="invoicing-modal_form">
              <button
                onClick={() => setShowPasswordForm(false)}
                className="invoicing-modal_icon">
                <img alt="close" src={Close} />
              </button>
              <Form />
            </div>
          </div>
        </>
      )}

      <div
        className={clsx(
          'invoicing_container',
          showPasswordForm && 'invoicing_container--dark',
        )}>
        <h1 className="invoicing_head">{t('invoicing_headline')}</h1>
        <div className="invoicing_flex">
          <img src={InvoiceIllustration} alt="InvoiceIllustration" />
          <div className="invoice_flex__content">
            <h2 className="invoicing_h2">{t('invoicing_content_head')}</h2>
            <ul className="content_list">
              {contentLists.map((list) => (
                <li key={list.title}>
                  <div className="list_head">
                    <img src={Checkmark} alt="checkmark-icon" />
                    <span>{t(list.title)}</span>
                  </div>
                  <span className="list_desc">{t(list.desc)}</span>
                </li>
              ))}
            </ul>
            {!isEmailExists ? (
              <Button
                className="invoice_btn"
                classic
                func={() => setShowPasswordForm(true)}>
                {t('invoicing_btn_new_acc')}
              </Button>
            ) : (
              <Button className="invoice_btn" classic func={redirectUser}>
                {`${t('invoicing_btn_email_exists')} “${userData.email}”`}
              </Button>
            )}
            {country === 'be' &&
              <img src={Logo} alt="Logo" width={109} height={40} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoicing;
